html{
    height: 100%;
    box-sizing: border-box;
}

body{
    position: relative;
    margin: 0;
    min-height: 100%;
    box-sizing: inherit;
}

::-webkit-scrollbar-track
{
-webkit-box-shadow: inset 0 0 6px #1f2023;
border-radius: 0px;
background-color: #202124;
}
::-webkit-scrollbar
{
width: 5px;
background-color: #202124;
}
::-webkit-scrollbar-thumb
{
border-radius: 10px;
-webkit-box-shadow: inset 0 0 0px #1f2023;
background-color: #fff
;
}

.active{
    color: #e5e5e5;
}

.tabs .active{
    padding-top: 0.5rem;
    padding-bottom: 0.6rem; 
    padding-left: 1.75rem;
    padding-right: 1.75rem; 
    border-radius: 9999px; 
    background-color: #ffffff;
    color: #1f2023; 
}

.tabs {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem; 
    margin-left: 1.75rem;
    margin-right: 1.75rem;   
}